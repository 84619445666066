var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"patient-table-container px-0 mx-0"},[_c('PatientDetailPreview',{attrs:{"patient":_vm.selectedPatient}}),(_vm.modalShow)?_c('IodReportPopup',{attrs:{"modalShow":_vm.modalShow,"lensOrderId":_vm.lensOrderId},on:{"update:modalShow":function($event){_vm.modalShow=$event},"update:modal-show":function($event){_vm.modalShow=$event}}}):_vm._e(),_c('b-table',_vm._g({attrs:{"id":"table-patient-list","items":_vm.patientList,"fields":_vm.fields,"no-local-sorting":"","no-border-collapse":"","fixed":"","responsive":"","sticky-header":"55vh"},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(data.label)+" ")])]}},{key:"head(updated)",fn:function(){return [_c('div',{staticClass:"content light-border pointer",on:{"click":function($event){return _vm.toggleSortOrder(_vm.activitySortOrder, 'activitySortOrder')}}},[_c('div',{staticClass:"d-flex flex-column column-explanation"},[_c('span',[_vm._v(_vm._s(_vm.t('activity')))]),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(_vm._s(_vm.t('dateHint')))])])])]},proxy:true},{key:"cell(updated)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('span',{staticClass:"text-caption text-gray-dark"},[_c('span',{staticClass:"white-space-pre"},[_vm._v(_vm._s(_vm._f("date")(value)))]),(!item.active)?[_vm._v(" "+_vm._s(_vm.t('inactive'))+" ")]:_vm._e()],2)]}},{key:"cell(actions)",fn:function(data){
var _obj;
return [_c('div',{staticClass:"d-flex"},[(
                        _vm.checkPermissions(( _obj = {}, _obj[_vm.PERMISSIONS.CREATE_PATIENT] = _vm.PERMISSIONS_VALUES.ENABLED, _obj ))
                    )?_c('b-icon',{staticClass:"pointer mr-2",attrs:{"icon":"pencil-fill","variant":"gray-dark"},on:{"click":function($event){return _vm.goToPatientDetailsEditMode(data.item.patientId)}}}):_vm._e(),_c('b-img',{directives:[{name:"b-modal",rawName:"v-b-modal.patient-detail-preview",modifiers:{"patient-detail-preview":true}}],staticClass:"mr-2",attrs:{"src":_vm.previewIcon},on:{"click":function($event){_vm.selectedPatient = data.item}}}),(data.item.priority)?_c('b-img',{attrs:{"src":_vm.asterisk,"width":"18","height":"18"}}):_vm._e()],1)]}},{key:"head(expanded)",fn:function(){return [_c('div',{staticClass:"content pointer",on:{"click":_vm.expandAll}},[_vm._v(" "+_vm._s(_vm.t('all'))+" ")])]},proxy:true},{key:"cell(expanded)",fn:function(ref){
                    var item = ref.item;
                    var value = ref.value;
return [_c('div',{staticClass:"d-flex"},[(_vm.expandable(item))?_c('b-icon',{staticClass:"ml-1 pointer",attrs:{"icon":value ? 'chevron-up' : 'chevron-down'},on:{"click":function($event){return _vm.expand(item)}}}):_vm._e()],1)]}},{key:"head(name)",fn:function(){return [_c('div',{staticClass:"content pointer",on:{"click":function($event){return _vm.toggleSortOrder(_vm.patientNameSortOrder, 'patientNameSortOrder')}}},[_c('div',{staticClass:"d-flex flex-column column-explanation"},[_c('span',[_vm._v(_vm._s(_vm.t('patientId')))]),(!_vm.activeCustomer.hidePatientNames)?_c('div',{staticClass:"mt-1 mr-2 column-explanation-text w-100"},[_vm._v(" "+_vm._s(_vm.t('patientNameLastFirst'))+" ")]):_vm._e()])])]},proxy:true},{key:"cell(name)",fn:function(data){return [_c('custom-router-link',{staticClass:"patient-link text-primary d-block mb-1 text-caption",attrs:{"to":{
                    name: 'PatientDetails',
                    params: {patientId: data.item.patientId},
                }}},[_vm._v(" "+_vm._s(data.item.patientRef)+" ")]),(!_vm.activeCustomer.hidePatientNames)?_c('p',{staticClass:"text-caption light text-gray-dark mb-0"},[_vm._v(" "+_vm._s(_vm.namesFormatter(data.item.lastName, data.item.firstName))+" ")]):_vm._e()]}},{key:"head(dateOfBirth)",fn:function(){return [_c('div',{staticClass:"content"},[_c('div',{staticClass:"d-flex flex-column column-explanation"},[_c('span',[_vm._v(_vm._s(_vm.t('dob')))]),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(_vm._s(_vm.t('dateHint')))])])])]},proxy:true},{key:"cell(dateOfBirth)",fn:function(data){return [_c('span',{staticClass:"text-caption text-gray-dark"},[_vm._v(" "+_vm._s(_vm._f("date")(data.value,{isUTC: false}))+" ")])]}},{key:"head(doctorFullName)",fn:function(){return [_c('div',{staticClass:"content pointer",on:{"click":function($event){return _vm.toggleSortOrder(_vm.surgeonSortOrder, 'surgeonSortOrder')}}},[_c('div',{staticClass:"d-flex flex-column column-explanation"},[_c('span',[_vm._v(_vm._s(_vm.t('surgeon')))]),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(" "+_vm._s(_vm.t('LastFirst'))+" ")])])])]},proxy:true},{key:"cell(doctorFullName)",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-caption heavy d-flex align-items-start"},[_vm._v(" "+_vm._s(_vm.surgeonIdNameFormat( item.ocosDoctorId, ((item.doctorLastName) + ", " + (item.doctorFirstName)), item.doctorActive ))+" ")])]}},{key:"head(odCalculations)",fn:function(){return [_c('div',{staticClass:"header-box left d-flex align-items-center pl-2 pt-1"},[_c('img',{attrs:{"src":require("@/assets/eye_icon_white.svg")}}),_c('img',{attrs:{"src":require("@/assets/eye_icon_gray_orig.svg")}})]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.t('calculations'))+" ")])]},proxy:true},{key:"cell(odCalculations)",fn:function(data){return [_vm._l((_vm.lensRange(
                    data.item.odLensDetails,
                    'selectedPrescription',
                    false
                )),function(lensDetail,i){return _c('div',{key:i},[_c('p',{staticClass:"text-caption light text-gray-dark mb-1 pl-0 ml-0"},[_vm._v(" "+_vm._s(_vm.decimalSeparatorFormatter( lensDetail['selectedModel'], _vm.currentUser.decimalSeparator ))+" ")]),_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"text-caption heavy text-black mb-0",attrs:{"title":lensDetail['selectedPrescriptionString']}},[_vm._v(" "+_vm._s(_vm.decimalSeparatorFormatter( lensDetail['selectedPrescriptionString'], _vm.currentUser.decimalSeparator ))+" ")]),(
                            _vm.hasPatientCalculationPermission &&
                            lensDetail['selectedPrescriptionString']
                        )?_c('b-button',{staticClass:"pointer btn-print",attrs:{"id":"printReport"},on:{"click":function($event){_vm.printReport(
                                _vm.getPreOpDataSetId(data.item.preOpDataItems, _vm.EyeSides.OD)
                            )}}},[_c('b-icon',{staticClass:"pointer ml-2",attrs:{"icon":"printer-fill"}})],1):_vm._e()],1)])}),(
                    _vm.hasPatientCalculationPermission &&
                    !_vm.lensRange(data.item.odLensDetails, 'selectedPrescription', false).length &&
                    _vm.calculationStatus(data.item.preOpDataItems, _vm.EyeSides.OD)
                )?_c('p',{staticClass:"text-nowrap text-caption heavy text-black mb-1"},[_vm._v(" "+_vm._s(_vm.calculationStatus(data.item.preOpDataItems, _vm.EyeSides.OD))+" "),_c('b-button',{staticClass:"pointer btn-print",attrs:{"id":"printReport"},on:{"click":function($event){_vm.printReport(_vm.getPreOpDataSetId(data.item.preOpDataItems, _vm.EyeSides.OD))}}},[_c('b-icon',{staticClass:"pointer ml-2",attrs:{"icon":"printer-fill"}})],1)],1):_vm._e()]}},{key:"head(odLens)",fn:function(){return [_c('div',{staticClass:"header-box middle d-flex align-items-center"},[_c('span',{staticClass:"text-subheader heavy text-white"},[_vm._v("OD")])]),_c('div',{staticClass:"content"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"offset":"3","cols":"6"}},[_vm._v(" "+_vm._s(_vm.t('odLens'))+" ")])],1)],1)]},proxy:true},{key:"cell(odLens)",fn:function(data){return _vm._l((_vm.lensRange(
                    data.item.odLensDetails,
                    'reservedPrescription',
                    data.item.expanded
                )),function(lensDetail,i){return _c('div',{key:i,staticClass:"np lens-nudge min-h-sub-row"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"3"}},[(_vm.showIodButton(lensDetail))?_c('b-button',{staticClass:"btn-iod",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.iodBtnClicked(lensDetail)}}},[_vm._v(" "+_vm._s(_vm.isSidButton(lensDetail))+" ")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"9"}},[_c('div',[_c('p',{staticClass:"text-caption light text-gray-dark mb-1"},[_vm._v(" "+_vm._s(lensDetail['reservedModel'])+" ")]),_c('p',{staticClass:"text-caption heavy text-black mb-0",attrs:{"title":lensDetail['reservedPrescriptionString']}},[_vm._v(" "+_vm._s(_vm.decimalSeparatorFormatter( lensDetail['reservedPrescriptionString'], _vm.currentUser.decimalSeparator ))+" ")])])])],1)],1)})}},{key:"head(odOrder)",fn:function(){return [_c('div',{staticClass:"header-box right"}),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.t('order'))+" ")])]},proxy:true},{key:"cell(odOrder)",fn:function(data){return _vm._l((_vm.lensRange(
                    data.item.odLensDetails,
                    'reservedPrescription',
                    data.item.expanded
                )),function(lensDetail,i){return _c('div',{key:i,staticClass:"d-flex align-items-start np lens-nudge min-h-sub-row"},[_c('div',[_c('p',{staticClass:"text-caption heavy text-black mb-1"},[_vm._v(" "+_vm._s(_vm.getReservationOrderStatus(lensDetail.orderStatus, { isConsignment: _vm.isConsignment(lensDetail), }))+" - "),_c('custom-router-link',{attrs:{"to":{
                                name: _vm.pageName[lensDetail.orderStatus],
                                params: {orderId: lensDetail.orderId},
                            }}},[_vm._v(" "+_vm._s(lensDetail.orderNumber))])],1),(lensDetail.shippingProvider)?_c('p',{staticClass:"text-caption heavy text-black mb-1"},[_vm._v(" "+_vm._s(lensDetail.shippingProvider)+": "+_vm._s(lensDetail.shippingNumber)+" ")]):_vm._e(),(lensDetail.serial)?_c('p',{staticClass:"text-caption light text-gray-dark mb-0 text-nowrap",attrs:{"title":lensDetail.serial}},[_vm._v(" "+_vm._s(_vm.t(_vm.isConsignment(lensDetail) ? 'invLookup_Consignment' : 'serialNum'))+" "+_vm._s(lensDetail.serial)+" ")]):_vm._e()])])})}},{key:"head(osCalculations)",fn:function(){return [_c('div',{staticClass:"header-box left"}),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.t('calculations'))+" ")])]},proxy:true},{key:"cell(osCalculations)",fn:function(data){return [_vm._l((_vm.lensRange(
                    data.item.osLensDetails,
                    'selectedPrescription',
                    false
                )),function(lensDetail,i){return _c('div',{key:i},[_c('p',{staticClass:"text-caption light text-gray-dark mb-1"},[_vm._v(" "+_vm._s(_vm.decimalSeparatorFormatter( lensDetail['selectedModel'], _vm.currentUser.decimalSeparator ))+" ")]),_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"text-caption heavy text-black mb-0",attrs:{"title":lensDetail['selectedPrescriptionString']}},[_vm._v(" "+_vm._s(_vm.decimalSeparatorFormatter( lensDetail['selectedPrescriptionString'], _vm.currentUser.decimalSeparator ))+" ")]),(
                            _vm.hasPatientCalculationPermission &&
                            lensDetail['selectedPrescriptionString']
                        )?_c('b-button',{staticClass:"pointer btn-print",attrs:{"id":"printReport"},on:{"click":function($event){_vm.printReport(
                                _vm.getPreOpDataSetId(data.item.preOpDataItems, _vm.EyeSides.OS)
                            )}}},[_c('b-icon',{staticClass:"pointer ml-2",attrs:{"icon":"printer-fill"}})],1):_vm._e()],1)])}),(
                    _vm.hasPatientCalculationPermission &&
                    !_vm.lensRange(data.item.osLensDetails, 'selectedPrescription', false).length &&
                    _vm.calculationStatus(data.item.preOpDataItems, _vm.EyeSides.OS)
                )?_c('p',{staticClass:"text-nowrap text-caption heavy text-black mb-1"},[_vm._v(" "+_vm._s(_vm.calculationStatus(data.item.preOpDataItems, _vm.EyeSides.OS))+" "),_c('b-button',{staticClass:"pointer btn-print",attrs:{"id":"printReport"},on:{"click":function($event){_vm.printReport(_vm.getPreOpDataSetId(data.item.preOpDataItems, _vm.EyeSides.OS))}}},[_c('b-icon',{staticClass:"pointer ml-2",attrs:{"icon":"printer-fill"}})],1)],1):_vm._e()]}},{key:"head(osLens)",fn:function(){return [_c('div',{staticClass:"header-box middle d-flex align-items-center"},[_c('span',{staticClass:"text-subheader heavy text-white"},[_vm._v("OS")])]),_c('div',{staticClass:"content"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"offset":"3","cols":"6"}},[_vm._v(" "+_vm._s(_vm.t('osLens'))+" ")])],1)],1)]},proxy:true},{key:"cell(osLens)",fn:function(data){return _vm._l((_vm.lensRange(
                    data.item.osLensDetails,
                    'reservedPrescription',
                    data.item.expanded
                )),function(lensDetail,i){return _c('div',{key:i,staticClass:"np lens-nudge min-h-sub-row"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"3"}},[(_vm.showIodButton(lensDetail))?_c('b-button',{staticClass:"btn-iod",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.iodBtnClicked(lensDetail)}}},[_vm._v(" "+_vm._s(_vm.isSidButton(lensDetail))+" ")]):_vm._e()],1),_c('b-col',{attrs:{"cols":"9"}},[_c('div',[_c('p',{staticClass:"text-caption light text-gray-dark mb-1"},[_vm._v(" "+_vm._s(lensDetail['reservedModel'])+" ")]),_c('p',{staticClass:"text-caption heavy text-black mb-0",attrs:{"title":lensDetail['reservedPrescriptionString']}},[_vm._v(" "+_vm._s(_vm.decimalSeparatorFormatter( lensDetail['reservedPrescriptionString'], _vm.currentUser.decimalSeparator ))+" ")])])])],1)],1)})}},{key:"head(osOrder)",fn:function(){return [_c('div',{staticClass:"header-box right d-flex align-items-center justify-content-end pr-2 pt-1"},[_c('img',{attrs:{"src":require("@/assets/eye_icon_gray_orig.svg")}}),_c('img',{attrs:{"src":require("@/assets/eye_icon_white.svg")}})]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.t('order'))+" ")])]},proxy:true},{key:"cell(osOrder)",fn:function(data){return _vm._l((_vm.lensRange(
                    data.item.osLensDetails,
                    'reservedPrescription',
                    data.item.expanded
                )),function(lensDetail,i){return _c('div',{key:i,staticClass:"d-flex align-items-start np lens-nudge min-h-sub-row"},[_c('div',[_c('p',{staticClass:"text-caption heavy text-black mb-1"},[_vm._v(" "+_vm._s(_vm.getReservationOrderStatus(lensDetail.orderStatus, { isConsignment: _vm.isConsignment(lensDetail), }))+" - "),_c('custom-router-link',{attrs:{"to":{
                                name: _vm.pageName[lensDetail.orderStatus],
                                params: {orderId: lensDetail.orderNumber},
                            }}},[_vm._v(" "+_vm._s(lensDetail.orderNumber))])],1),(lensDetail.shippingProvider)?_c('p',{staticClass:"text-caption heavy text-black mb-1"},[_vm._v(" "+_vm._s(lensDetail.shippingProvider)+": "+_vm._s(lensDetail.shippingNumber)+" ")]):_vm._e(),(lensDetail.serial)?_c('p',{staticClass:"text-caption light text-gray-dark mb-0",attrs:{"title":lensDetail.serial}},[_vm._v(" "+_vm._s(_vm.t(_vm.isConsignment(lensDetail) ? 'invLookup_Consignment' : 'serialNum'))+" "+_vm._s(lensDetail.serial)+" ")]):_vm._e()])])})}}])},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }